.hero{
    background-image: linear-gradient(to right, rgba(#26262e6c, 0.5), rgba(#26262e6c, 0.5)), url(../../assets/bg.jpg);
    background-position: center;
    grid-column: full-start / full-end;
    overflow: hidden;
    height: 100vh;
    backdrop-filter: blur(30px);


    &__container {
        height: 100%;
        color: $color-grey-light-1;
        font-family: $font-primary;
        transition: moveInBottom .2s;
        display: grid;
        grid-template-rows: repeat(4, max-content);
        grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
        justify-content: center;
        align-content: center;

        line-height: 6rem;
        position: relative;

        @media only screen and (max-width: $bp-small) {
          line-height: 5rem;
        }
    }

    &__h1 {
        display: inline-block;
        opacity: 0;
        font-weight: 300;
        animation: fadeIn 1s ease-out .8s forwards;
        font-size: 7rem;
        // letter-spacing: .2rem;

        @media only screen and (max-width: $bp-medium) {
          font-size: 6rem;
        }

        @media only screen and (max-width: $bp-small) {
          font-size: 5rem;
        }
        @media only screen and (max-width: $bp-smaller) {
          font-size: 4.5rem;
        }

        @media only screen and (max-width: $bp-smallest) {
          font-size: 3rem;
          line-height: 3rem;
        }
    }
    
    &__h3 {
        display: inline-block;
        font-size: 3rem;
        font-weight: 300;
        animation: moveInBottom 1s ease-out forwards;
        opacity: 0;
        &--anim-delay {
          animation: moveInTop 1s ease-out 1.5s forwards;
        }

        @media only screen and (max-width: $bp-smaller) {
          font-size: 2.5rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          font-size: 1.8rem;
          line-height: 3rem;
        }
    }

    &__line {
        height:0;
        width:1px;
        border-top:1px solid #fff;
        margin-top: 2rem;
        -webkit-animation: increaseWidth 1s;
        -moz-animation:    increaseWidth 1s; 
        -o-animation:      increaseWidth 1s; 
        animation:         increaseWidth 1s;
        animation-delay: .8s;
        animation-fill-mode: forwards;

        @media only screen and (max-width: $bp-small) {
          margin-top: .5rem;
        }
        @media only screen and (max-width: $bp-small) {
          margin-top: 1rem;
        }
    }
}

.arrow {
    display: inline-block;
    height: 1.8rem;
    width: 1.8rem;
    border-top: .2rem solid #fff;
    border-right: .2rem solid #fff;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: rotate(135deg);
    transition: all .2s ease-out;
    opacity: 0;
    animation: fadeIn 1s ease-out 2s forwards;
    
    &:hover {
        transform: rotate(135deg) scale(1.3);
    }
}


// // n is number of stars required
// @function multiple-box-shadow ($n) {
//   $value: '#{random(2000)}px #{random(2000)}px #FFF';
//   @for $i from 2 through $n {
//     $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
//     }
//   @return unquote($value);
// }

// $shadows-small:  multiple-box-shadow(700);
// $shadows-medium: multiple-box-shadow(200);
// $shadows-big:    multiple-box-shadow(100);

// #stars {
//   width: 1px;
//   height: 1px;
//   background: transparent;
//   box-shadow: $shadows-small;
//   animation	: animateDots 50s linear infinite;
    
//   &:after{
//     content: " ";
//     position: absolute;
//     top: 2000px;
//     width: 1px;
//     height: 1px;
//     background: transparent;
//     box-shadow: $shadows-small;
//   }
// }

// #stars2 {
//   width: 2px;
//   height: 2px;
//   background: transparent;
//   box-shadow: $shadows-medium;
//   animation: animateDots 100s linear infinite;
    
//   &:after {
//     content: "";
//     position: absolute;
//     top: 2000px;
//     width: 2px;
//     height: 2px;
//     background: transparent;
//     box-shadow: $shadows-medium;
//     }
// }

// #stars3{
//   width: 3px;
//   height: 3px;
//   background: transparent;
//   box-shadow: $shadows-big;
//   animation: animateDots 150s linear infinite;
    
//   &:after{
//     content: " ";
//     position: absolute;
//     top: 2000px;
//     width: 3px;
//     height: 3px;
//     background: transparent;
//     box-shadow: $shadows-big;
//     }
// }

// @keyframes animateDots {
//     from {
//         transform: translateY(-2000px);
//     }
//     to {
//         transform: translateY(0);
//     }
// }