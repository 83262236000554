// COLOR VARIABLES
$color-primary-light: #3E4A56;
$color-primary: #101d2c;

$color-secondary: #B89452;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-grey-dark-1: #54483A;
$color-grey-dark-2: #6D5D4B;

$color-brown: #31281D;
$color-purple: #134074;
$color-light: #0D103D;

// FONT VARIABLES
$font-primary: 'Quicksand', sans-serif;
$font-display: 'Lora', serif;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em;   // 1200px
$bp-large: 62.5em;   // 1000px
$bp-medium: 50em;    // 800px
$bp-med-700: 43.75;   // 700px
$bp-small: 37.5em;    // 600px
$bp-smaller: 26.25em;    // 420px
$bp-smallest: 25.375em;    // 360px

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  font-family: $font-primary;
  font-weight: 300;
  line-height: 1.6;
  background-color: $color-grey-light-1;
  text-rendering: optimizeLegibility; 
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.app-container {
  display: grid;
  grid-template-columns: [full-start] 1fr [center-start] repeat(6, [col-start] 1fr [col-end]) [center-end] 1fr [full-end];
  grid-template-rows: 100vh 5rem min-content min-content min-content min-content;
}
