.expertise {
  grid-column: center-start / center-end;
  display: grid;
  grid-template-rows: min-content max-content;
  row-gap: 2rem;
  padding: 2rem;
  margin: 2rem;
  transition: all .2s;
  
  @media only screen and (max-width: $bp-medium) {
    row-gap: 1.5rem;
    padding: 1.2rem;
    margin: 1.2rem;
  }

  @media only screen and (max-width: $bp-small) {
    row-gap: 1rem;
    padding: .5rem;
    margin: .5rem;
  }

  &__h2 {
    font-family: $font-primary;
    font-weight: 300;
    font-size: 5rem;
    text-align: center;
    animation: moveInTop 1s ease-out;
    color: $color-purple;

    @media only screen and (max-width: $bp-smallest) {
      font-size: 3.5rem;
    }
  }

  
  &__tech {
    display: grid;
    row-gap: 1rem;
    & h3 {
        font-family: $font-primary;
        font-weight: 300;
        font-size: 3rem;
        opacity: 0;
        animation: fadeIn 1s ease-out .3s forwards;
        color: $color-purple;

        @media only screen and (max-width: $bp-smallest) {
          font-size: 2.2rem;
        }
    }
  }

  .tech_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, max-content));
    column-gap: 1rem;

    & > h3 {
        grid-column: 1 / -1;
        
    }
    @media only screen and (max-width: $bp-largest) {
      grid-template-columns: repeat(auto-fit, minmax(10rem, max-content));

    }

    @media only screen and (max-width: $bp-large) {
      grid-template-columns: repeat(auto-fit, minmax(9rem, max-content));

    }
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: repeat(auto-fit, minmax(10rem, max-content));

    }
    @media only screen and (max-width: $bp-smallest) {
      grid-template-columns: repeat(auto-fit, minmax(10rem, max-content));

    }
  }
}

.dev-icon__container {
    opacity: 0;
    display: flex;
    align-items: center;
    & > p {
        font-size: 1.8rem;
        margin: 2rem 1rem;

        @media only screen and (max-width: $bp-large) {
          margin: 1.5rem .5rem;
        }
        @media only screen and (max-width: $bp-medium) {
          margin: 1.5rem .5rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          font-size: 1.4rem;
        }
    }

    animation: moveInBottom 1s .5s forwards;

}

.react-icons {
    font-size: 2.5rem;
    vertical-align: center;
    
    &:hover{
      transform: scale(1.1);
  }
}