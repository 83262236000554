.work {
  grid-column: center-start / center-end;
  padding: 1.5rem;
  margin: 1.5rem;
  transition: all .2s;

  @media only screen and (max-width: $bp-largest) {
    margin: 1rem 0;
    padding: 1rem 0;
  }

  @media only screen and (max-width: $bp-medium) {
    margin: .5rem 0;
    padding: .5rem 0;
  }

  
  @media only screen and (max-width: $bp-smallest) {
    margin: .2rem 0;
    padding: .2rem 0;
  }

  
  &__h2 {
    font-family: $font-primary;
    font-weight: 300;
    font-size: 5rem;
    text-align: center;
    opacity: 0;
    animation: moveInTop 1s .3s ease-out forwards;
    color: $color-purple;

    @media only screen and (max-width: $bp-smaller) {
      font-size: 4rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      font-size: 3rem;
    }
  }   

  &__container {
    @supports(display: grid) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      column-gap: 1.5rem;
      align-items: start;
    }
    margin: 5rem 0;

    @media only screen and (max-width: $bp-largest) {
      margin: 2rem 0;
    }

    @media only screen and (max-width: $bp-medium) {
      margin: 1rem 0;
    }

    @media only screen and (max-width: $bp-smaller) {
      margin: .5rem 0;
    }

    @media only screen and (max-width: $bp-smallest) {
      margin: .2rem 0;
    }
  }

  &__list {
    padding: 2rem;
    margin: 1rem;
    opacity: 0;
    animation: fadeIn 1s ease-out .3s forwards;

    @media only screen and (max-width: $bp-largest) {
      padding: 1.5rem;
      margin: .5rem;
    }

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
      margin: .5rem;
    }

    
    @media only screen and (max-width: $bp-smallest) {
      margin: .2rem 0;
      padding: .5rem;
    }
  }

  &__h4 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 1rem 0;

    @media only screen and (max-width: $bp-smallest) {
      font-size: 2rem;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem 0;
    @media only screen and (max-width: $bp-smallest) {
      font-size: 1.8rem;
    }
  }

  &__desc {
    font-size: 1.5rem;

    @media only screen and (max-width: $bp-smallest) {
      font-size: 1.3rem;
    }
  }
}