.header {
  background-color: #ffffff;
  grid-column: full-start / full-end;
  padding: 0 3rem;  
  box-shadow: 1px -5px 10px #000000;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;

  @media only screen and (max-width: $bp-smallest) {
    padding: 0 1rem;
  }

  &__container {
      list-style: none;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, max-content);
      column-gap: 3rem;
      justify-content: end;
      align-items: center;

      @media only screen and (max-width: $bp-medium) {
        justify-content: center;
        column-gap: 5rem;
      }

      @media only screen and (max-width: $bp-small) {
        column-gap: 3rem;
      }
      
      @media only screen and (max-width: $bp-smaller) {
        column-gap: 1rem;
      }

      @media only screen and (max-width: $bp-smallest) {
        column-gap: 0rem;
      }
      
    & .nav-active {
      border-bottom: 1px solid #000000;
    }
  }

  &__item {
    padding: 1rem;
    position: relative;

    &:hover {
      transform: translateY(-.1rem);
    }

    &:after{
      content: '';
      position: absolute;
      width: 0; height: 1px;
      display: block;
      margin-top: 5px;
      right: 0;
      background: #000000;
      transition: width .2s ease;
      -webkit-transition: width .2s ease;
    }
      
    &:hover:after{
      width: 100%;
      left: 0;
      background: #000000;
    }
  }

  &__link{
      font-size: 1.3rem;
      font-weight: 400;
      color: black;
      text-decoration: none;
      font-family: $font-display;
      text-transform: uppercase;
      text-align: center;
      display: block;
      transition: all .2s;
      cursor: pointer;
  }
}